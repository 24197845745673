.mainContent {
  background: transparent linear-gradient(180deg, #0E0506 0%, #100A0F 100%) 0% 0% no-repeat padding-box;
}

.playerArena {
  width: 100%;
  // height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 4;
}

.smallPc {
  .fon {
      height: 250px;
      background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D1A 26%, #1D1B2D73 50%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
}

.tab {
  .fon {
      height: 548px;
      background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D73 26%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
  .playerArena {
      &-section {
          order: 2;
          display: flex;
          flex-direction: column;
          gap: calc(6px * var(--device-pixel-ratio));
      }
  }
}

@media only screen and (max-width: 540px) {
  .fon {
      height: 68.065vh;
      background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2DB3 22%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }
  .playerArena {
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 1.41vw;
      &-section {
          order: 3;
          // height: 24.3vh;
          // width: 41.86vw
      }
  }
}