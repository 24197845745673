header {
  position: relative;
  z-index: 10;
  height: calc(84px * var(--device-pixel-ratio));
  margin: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 40px * var(--device-pixel-ratio)));
  & .game-controller {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      flex: 1;
      & .mobile-icon {
          display: none;
      }
      &>.info-box {
          width: fit-content;
          background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
          border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
          border-radius: calc(8px * var(--device-pixel-ratio));
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          height: 100%;
          padding: calc(16px * var(--device-pixel-ratio)) calc(32px * var(--device-pixel-ratio));
          & .game-info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: calc(16px * var(--device-pixel-ratio));
              border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
              padding-right: calc(20px * var(--device-pixel-ratio));
              margin-right: calc(12px * var(--device-pixel-ratio));
              width: fit-content;
              height: 100%;
              & .title {
                  text-align: right;
                  font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                  letter-spacing: calc(0.6px * var(--device-pixel-ratio));
                  color: $main-text-color;
                  text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
                  width: 100%;
              }
              & .description,
              & .description span {
                  text-align: right;
                  font: 300 calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                  letter-spacing: calc(0.3px * var(--device-pixel-ratio));
                  color: $main-text-color;
                  text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
                  width: 100%;
              }
          }
          & .min-max-stake {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              gap: calc(16px * var(--device-pixel-ratio));
              border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
              padding-right: calc(20px * var(--device-pixel-ratio));
              margin-right: calc(12px * var(--device-pixel-ratio));
              text-transform: capitalize;
              &-item {
                  font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                  letter-spacing: calc(0.6px * var(--device-pixel-ratio));
                  color: #FFFFFF;
                  text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
                  white-space: nowrap;
                  & span {
                      font-weight: bold;
                      font-family: "SF Pro Text Bold";
                      text-transform: uppercase;
                  }
              }
              .divider {
                  display: none;
              }
          }
          & .user-info {
              display: flex;
              flex-direction: column;
              position: relative;
              gap: calc(16px * var(--device-pixel-ratio));
              width: fit-content;
              min-width: calc(166px * var(--device-pixel-ratio));
              &-item {
                  display: flex;
                  align-items: center;
                  & .icon {
                      width: calc(22px * var(--device-pixel-ratio));
                      height: calc(18px * var(--device-pixel-ratio));
                      margin-right: calc(8px * var(--device-pixel-ratio));
                  }
                  & .title {
                      text-align: left;
                      font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                      letter-spacing: calc(0.6px * var(--device-pixel-ratio));
                      color: rgba(255, 255, 255, 1);
                      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
                      min-width: calc(72px * var(--device-pixel-ratio));
                      margin-right: calc(17px * var(--device-pixel-ratio));
                  }
                  & .description {
                      text-align: left;
                      font: 900 calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Light";
                      letter-spacing: calc(0.6px * var(--device-pixel-ratio));
                      color: rgba(255, 204, 0, 1);
                      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
                      text-transform: uppercase;
                      white-space: nowrap;
                      width: calc(41px * var(--device-pixel-ratio))fit-content;
                  }
              }
          }
      }
      & .settings-box {
          & .template-button-list {
              & .template-button-item {
                  width: calc(50px * var(--device-pixel-ratio));
                  height: calc(50px * var(--device-pixel-ratio));
                  &:not(:first-child) {
                      margin-left: calc(22px * var(--device-pixel-ratio));
                  }
                  & .tooltip-wrapper {
                      & svg {
                          width: 100%;
                          height: 100%;
                      }
                  }
              }
          }
      }
  }
}

.settings-box-sound-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: calc(130px * var(--device-pixel-ratio));
  & .icon {
      width: calc(30px * var(--device-pixel-ratio));
      height: calc(30px * var(--device-pixel-ratio));
      cursor: pointer;
  }
  & input {
      height: auto;
      -webkit-appearance: none;
      background: #1212129A;
      outline: none;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
  }
  input::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      border: 4px solid #fff;
      box-shadow: -407px 0 0 400px rgb(248, 205, 71);
  }
}

.middlePc {
  header {
      height: calc(64px * var(--device-pixel-ratio));
      margin: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
      width: calc((100% - 32px * var(--device-pixel-ratio)));
      & .game-controller {
          &>.info-box {
              width: fit-content;
              padding: calc(12px * var(--device-pixel-ratio)) calc(24px * var(--device-pixel-ratio));
              & .game-info {
                  gap: calc(12px * var(--device-pixel-ratio));
                  padding-right: calc(16px * var(--device-pixel-ratio));
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  width: fit-content;
                  & .title {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  & .description,
                  & .description span {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
              }
              & .min-max-stake {
                  gap: calc(12px * var(--device-pixel-ratio));
                  width: calc(97px * var(--device-pixel-ratio));
                  padding-right: calc(16px * var(--device-pixel-ratio));
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  &-item {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  .divider {
                      display: none;
                  }
              }
              & .user-info {
                  gap: calc(12px * var(--device-pixel-ratio));
                  width: fit-content;
                  min-width: calc(121px * var(--device-pixel-ratio));
                  &-item {
                      & .icon {
                          width: calc(18px * var(--device-pixel-ratio));
                          height: calc(14px * var(--device-pixel-ratio));
                          margin-right: calc(6px * var(--device-pixel-ratio));
                      }
                      & .title {
                          font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                          min-width: calc(60px * var(--device-pixel-ratio));
                          margin-right: calc(6px * var(--device-pixel-ratio));
                      }
                      & .description {
                          font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                          width: fit-content;
                      }
                  }
              }
          }
          & .settings-box {
              & .template-button-list {
                  & .template-button-item {
                      width: calc(38px * var(--device-pixel-ratio));
                      height: calc(38px * var(--device-pixel-ratio));
                      &:not(:first-child) {
                          margin-left: calc(16px * var(--device-pixel-ratio));
                      }
                  }
              }
          }
      }
  }
}

.smallPc {
  header {
      width: calc(100% - 32px * var(--device-pixel-ratio));
      height: calc(64px * var(--device-pixel-ratio));
      margin: calc(16px * var(--device-pixel-ratio));
      & .game-controller {
          &>.info-box {
              max-width: fit-content;
              padding: calc(11px * var(--device-pixel-ratio)) calc(24px * var(--device-pixel-ratio));
              & .game-info {
                  gap: calc(12px * var(--device-pixel-ratio));
                  padding-right: calc(16px * var(--device-pixel-ratio));
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  width: fit-content;
                  & .title {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  & .description,
                  & .description span {
                      font: 300 calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
              }
              & .min-max-stake {
                  height: calc(40px * var(--device-pixel-ratio));
                  gap: calc(12px * var(--device-pixel-ratio));
                  padding-right: calc(16px * var(--device-pixel-ratio));
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  &-item {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  .divider {
                      display: none;
                  }
              }
              & .user-info {
                  gap: calc(12px * var(--device-pixel-ratio));
                  min-width: fit-content;
                  &-item {
                      height: calc(14px * var(--device-pixel-ratio));
                      & .icon {
                          width: calc(18px * var(--device-pixel-ratio));
                          height: calc(14px * var(--device-pixel-ratio));
                          margin-right: calc(6px * var(--device-pixel-ratio));
                      }
                      & .title {
                          font: bold calc(12px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                          min-width: calc(57px * var(--device-pixel-ratio));
                          margin-right: calc(12px * var(--device-pixel-ratio));
                      }
                      & .description {
                          font: bold calc(12px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                          width: fit-content;
                      }
                  }
              }
          }
          & .settings-box {
              & .template-button-list {
                  & .template-button-item {
                      width: calc(38px * var(--device-pixel-ratio));
                      height: calc(38px * var(--device-pixel-ratio));
                      &:not(:first-child) {
                          margin-left: calc(16px * var(--device-pixel-ratio));
                      }
                  }
              }
          }
      }
  }
}

.tab {
  header {
      height: calc(128px * var(--device-pixel-ratio));
      width: 100%;
      margin: 0;
      & .game-controller {
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: calc(16px * var(--device-pixel-ratio));
          &>.info-box {
              width: 100%;
              height: calc(72px * var(--device-pixel-ratio));
              flex-wrap: nowrap;
              padding: calc(16px * var(--device-pixel-ratio)) calc(29px * var(--device-pixel-ratio));
              border-radius: 0;
              border-left-color: transparent;
              border-right-color: transparent;
              justify-content: center;
              & .game-info {
                  gap: calc(12px * var(--device-pixel-ratio));
                  width: calc(230px * var(--device-pixel-ratio));
                  padding: 0 calc(16px * var(--device-pixel-ratio)) 0 0;
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  & .title {
                      text-align: left;
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  & .description {
                      text-align: left;
                      font: 300 calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                      span {
                          font: 300 calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
                          letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                      }
                  }
              }
              & .min-max-stake {
                  width: fit-content;
                  height: calc(40px * var(--device-pixel-ratio));
                  gap: calc(12px * var(--device-pixel-ratio));
                  padding-right: calc(16px * var(--device-pixel-ratio));
                  margin-right: calc(8px * var(--device-pixel-ratio));
                  &-item {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
                  .divider {
                      display: none;
                  }
              }
              & .user-info {
                  width: fit-content;
                  padding: 0;
                  gap: calc(12px * var(--device-pixel-ratio));
                  & .icon {
                      width: calc(18px * var(--device-pixel-ratio));
                      height: calc(14px * var(--device-pixel-ratio));
                      margin-right: calc(6px * var(--device-pixel-ratio));
                  }
                  & .title {
                      text-align: left;
                      font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                      min-width: calc(57px * var(--device-pixel-ratio));
                      margin-right: calc(12px * var(--device-pixel-ratio));
                  }
                  & .description {
                      text-align: left;
                      font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                  }
              }
          }
          & .settings-box {
              width: fit-content;
              & .template-button-list {
                  & .template-button-item {
                      width: calc(38px * var(--device-pixel-ratio));
                      height: calc(38px * var(--device-pixel-ratio));
                      margin-right: calc(16px * var(--device-pixel-ratio));
                      margin-left: 0;
                  }
              }
          }
      }
  }
}

@media only screen and (max-width: 540px) {
  header {
      height: fit-content;
      padding: 0;
      margin: 0;
      width: 100vw;
      & .game-controller {
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: 4px;
          &>.info-box {
              background: #1D1B2DB3 0% 0% no-repeat padding-box;
              border: 1px solid #87879480;
              flex-wrap: wrap;
              width: 100vw;
              padding: 10px 2.66vw;
              max-height: 100%;
              border-radius: 0;
              border-left-color: transparent;
              border-right-color: transparent;
              justify-content: center;
              & .game-info {
                  white-space: nowrap;
                  padding: 0 2.13vw 0 0;
                  margin: 0 2.13vw 0 0;
                  gap: 6px;
                  width: auto;
                  order: 1;
                  & .title {
                      font: 12px/12px "SF Pro Text Medium";
                      letter-spacing: 0.106vw;
                  }
                  & .description,
                  & .description span {
                      font: 300 12px/12px "SF Pro Text Light";
                      letter-spacing: 0.106vw;
                      text-align: left;
                  }
              }
              & .min-max-stake {
                  width: 100%;
                  height: 20px;
                  border-top: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                  padding-top: 5px;
                  margin-top: 6px;
                  padding-right: 0;
                  border-right-color: transparent;
                  margin-right: 0;
                  order: 3;
                  flex-wrap: nowrap;
                  justify-content: center;
                  flex-direction: row;
                  align-items: center;
                  gap: 1.2vw;
                  &-item {
                      font: 12px/12px "SF Pro Text Medium";
                      letter-spacing: 0.16vw;
                      text-shadow: 1px 1px 2px #000000;
                      display: flex;
                      & span {
                          padding-left: 1.2vw;
                      }
                  }
                  .divider {
                      display: block;
                      color: #FFFFFF;
                  }
              }
              & .user-info {
                  padding: 0;
                  white-space: nowrap;
                  width: 100%;
                  order: 2;
                  min-width: fit-content;
                  gap: 6px;
                  flex-direction: row;
                  justify-content: center;
                  margin-top: 5px;
                  &-item {
                      align-items: flex-end;
                      & .icon {
                          width: 14px;
                          height: 12px;
                          margin-right: 4px;
                      }
                      & .title {
                          font: bold 10px/12px "SF Pro Text Bold";
                          letter-spacing: 0.4px;
                          margin-right: 2.133vw;
                          min-width: 12.8vw;
                      }
                      & .description {
                          font: bold 10px/12px "SF Pro Text Bold";
                          letter-spacing: 0.4px;
                      }
                  }
              }
          }
          & .settings-box {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              &::before {
                  display: none;
              }
              & .template-button-list {
                  margin-right: 4.26vw;
                  & .template-button-item {
                      max-height: 8.5vw;
                      width: 32px;
                      height: 32px;
                      max-width: 4.79vh;
                      &:not(:first-child) {
                          margin-left: 4.26vw;
                      }
                      & .tooltip-wrapper {
                          display: flex;
                          align-items: center;
                          height: 100%;
                      }
                  }
              }
              &-sound-range {
                  position: unset;
                  padding: 0.5vw 1vw;
              }
          }
      }
  }
}

@media only screen and (max-width: 375px) {
  header {
      & .game-controller {
          &>.info-box {
              & .game-info {
                  width: 60%;
                  gap: 6px;
                  & .title {
                      font: 3.2vw/1.799vh "SF Pro Text Medium";
                      letter-spacing: 0.106vw;
                  }
                  & .description,
                  & .description span {
                      font: 300 3.2vw/1.799vh "SF Pro Text Light";
                  }
              }
              & .min-max-stake {
                  &-item {
                      font: 3.2vw/1.799vh "SF Pro Text Medium";
                  }
              }
              & .user-info {
                  &-item {
                      & .icon {
                          width: 14px;
                          height: 12px;
                          margin-right: 4px;
                      }
                      & .title {
                          font: bold 2.66/1.799vh "SF Pro Text Bold";
                          letter-spacing: 0.106vw;
                      }
                      & .description {
                          font: bold 2.66/1.799vh "SF Pro Text Bold";
                          letter-spacing: 0.106vw;
                      }
                  }
              }
          }
          & .settings-box {
              & .template-button-list {
                  margin-right: 16px;
              }
              &-sound-range {
                  position: unset;
              }
          }
      }
  }
}