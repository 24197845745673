.cards {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(224px * var(--device-pixel-ratio));
  width: calc(218px * var(--device-pixel-ratio));
  height: calc(260px * var(--device-pixel-ratio));
  left: calc(0px * var(--device-pixel-ratio));
  background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
  border-left-color: transparent;
  border-radius: calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
  padding: calc(20px * var(--device-pixel-ratio));
  gap: 17px;
  padding-top: 14px;
  .line {
      height: 1px;
      width: 100%;
      background-color: rgba(135, 135, 148, 0.5);
  }
  &-element {
      display: flex;
      height: calc(90px * var(--device-pixel-ratio));
      padding-top: calc(3px * var(--device-pixel-ratio));
      flex-direction: column;
      width: 100%;
      &:first-child {
          margin-bottom: 8px;
      }
      & .title {
          text-align: right;
          font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          font-family: 'SF Pro Text Medium';
          letter-spacing: calc(0.6px * var(--device-pixel-ratio));
          color: #FFFFFF;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
          margin-bottom: calc(8px * var(--device-pixel-ratio));
          text-transform: uppercase;
      }
  }
  &-list {
      list-style: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      flex-direction: row;
      & .card {
          display: flex;
          width: calc(45px * var(--device-pixel-ratio));
          height: calc(75px * var(--device-pixel-ratio));
          visibility: hidden;
          &.show {
              visibility: visible;
          }
          &:last-child {
              margin-left: calc(17px * var(--device-pixel-ratio));
          }
          &:not(:last-child):not(:first-child) {
              margin-left: calc(12px * var(--device-pixel-ratio));
          }
          & .flip-card {
              width: 100%;
              height: 100%;
              perspective: calc(1000px * var(--device-pixel-ratio));
          }
          & .flip-card-inner {
              position: relative;
              width: 100%;
              height: 100%;
              transition: transform 0.6s;
              transform-style: preserve-3d;
          }
          & .flip-card.active .flip-card-inner {
              transform: rotateY(180deg);
          }
          & .flip-card-front,
          .flip-card-back {
              position: absolute;
              width: 100%;
              height: 100%;
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
          }
          & .flip-card-front,
          & .flip-card-back {
              & img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
              }
          }
          & .flip-card-back {
              transform: rotateY(180deg);
              & .cardIcon {
                  height: 100%;
                  font-size: calc(64px * var(--device-pixel-ratio));
                  position: absolute;
                  top: 0;
                  box-shadow: none;
                  background: transparent;
              }
          }
          &:last-child {
              transform: rotate(90deg) translate(calc(8px * var(--device-pixel-ratio)), calc(0px * var(--device-pixel-ratio)));
          }
      }
      &-point {
          position: absolute;
          font: calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Medium";
          letter-spacing: calc(0.68px * var(--device-pixel-ratio));
          color: #FFFFFF;
          text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
          top: calc(-20px * var(--device-pixel-ratio));
          text-transform: uppercase;
          margin-left: calc(12px * var(--device-pixel-ratio));
          border-bottom: calc(1px * var(--device-pixel-ratio)) solid #fff;
          visibility: hidden;
          &.show {
              visibility: visible;
          }
      }
  }
}

.middlePc {
  .cards {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: calc(163px * var(--device-pixel-ratio));
      width: calc(168px * var(--device-pixel-ratio));
      height: calc(204px * var(--device-pixel-ratio));
      left: calc(0px * var(--device-pixel-ratio));
      background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
      border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
      border-left-color: transparent;
      border-radius: calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
      // padding: calc(16px * var(--device-pixel-ratio));
      padding-left: 16px;
      padding-top: 8px;
      gap: 11px;
      .line {
          height: 1px;
          width: 100%;
          background-color: rgba(135, 135, 148, 0.5);
      }
      &-element {
          display: flex;
          height: calc(70px * var(--device-pixel-ratio));
          // padding-top: calc(3px * var(--device-pixel-ratio));
          flex-direction: column;
          width: 100%;
          &:first-child {
              margin-bottom: 8px;
          }
          & .title {
              text-align: right;
              font: calc(12px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.6px * var(--device-pixel-ratio));
              color: #FFFFFF;
              text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
              margin-bottom: calc(1px * var(--device-pixel-ratio));
              text-transform: uppercase;
          }
      }
      &-list {
          list-style: none;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          flex-direction: row;
          & .card {
              display: flex;
              width: calc(36px * var(--device-pixel-ratio));
              height: calc(57px * var(--device-pixel-ratio));
              &:last-child {
                  margin-left: calc(11px * var(--device-pixel-ratio));
                  margin-top: -6px;
              }
              &:not(:last-child):not(:first-child) {
                  margin-left: calc(7px * var(--device-pixel-ratio));
              }
              & .flip-card {
                  width: 100%;
                  height: 100%;
                  perspective: calc(1000px * var(--device-pixel-ratio));
              }
              & .flip-card-inner {
                  position: relative;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.6s;
                  transform-style: preserve-3d;
              }
              & .flip-card.active .flip-card-inner {
                  transform: rotateY(180deg);
              }
              & .flip-card-front,
              .flip-card-back {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
              }
              & .flip-card-front,
              & .flip-card-back {
                  & img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                  }
              }
              & .flip-card-back {
                  transform: rotateY(180deg);
                  & .cardIcon {
                      height: 100%;
                      font-size: calc(64px * var(--device-pixel-ratio));
                      position: absolute;
                      top: 0;
                      box-shadow: none;
                      background: transparent;
                  }
              }
              &:last-child {
                  transform: rotate(90deg) translate(calc(8px * var(--device-pixel-ratio)), calc(0px * var(--device-pixel-ratio)));
              }
          }
          &-point {
              position: absolute;
              font: calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.68px * var(--device-pixel-ratio));
              color: #FFFFFF;
              text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
              top: calc(-20px * var(--device-pixel-ratio));
              text-transform: uppercase;
              margin-left: calc(12px * var(--device-pixel-ratio));
              border-bottom: calc(1px * var(--device-pixel-ratio)) solid #fff;
              visibility: hidden;
              &.show {
                  visibility: visible;
              }
          }
      }
  }
}

.smallPc {
  .cards {
      width: calc(166px * var(--device-pixel-ratio));
      height: calc(207px * var(--device-pixel-ratio));
      top: calc(160px * var(--device-pixel-ratio));
      padding: 16px;
      gap: 11px;
      &-body {
          height: calc(50px * var(--device-pixel-ratio));
      }
      &-element {
          height: 70px;
          .title {
              font: normal normal bold 12px/14px SF Pro Text;
              letter-spacing: 0.48px;
              font-family: "SF Pro Text Medium";
              margin-bottom: 1px;
          }
      }
      &-list {
          width: calc(130px * var(--device-pixel-ratio));
          &.history-card-list-view {
              width: calc(200px * var(--device-pixel-ratio));
          }
          // &.revers {
          //   flex-direction: row-reverse;
          //   .cards-list-point {
          //     margin-right: calc(8px * var(--device-pixel-ratio));
          //   }
          //   & .card {
          //     &:last-child {
          //       margin-right: calc(16px * var(--device-pixel-ratio));
          //       margin-left: 0;
          //     }
          //     &:not(:last-child):not(:first-child) {
          //       margin-right: calc(8px * var(--device-pixel-ratio));
          //       margin-left: 0;
          //     }
          //   }
          // }
          & .card {
              width: calc(34px * var(--device-pixel-ratio));
              height: calc(58px * var(--device-pixel-ratio));
              &:last-child {
                  margin-left: calc(14px * var(--device-pixel-ratio));
              }
              &:not(:last-child):not(:first-child) {
                  margin-left: calc(8px * var(--device-pixel-ratio));
              }
              & .flip-card-back {
                  & .cardIcon {
                      font-size: calc(50px * var(--device-pixel-ratio));
                  }
              }
              &:last-child {
                  transform: rotate(90deg) translate(calc(6px * var(--device-pixel-ratio)), calc(0px * var(--device-pixel-ratio)));
              }
          }
          &-point {
              font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.56px * var(--device-pixel-ratio));
              color: #FFFFFF;
              top: calc(-25px * var(--device-pixel-ratio));
              text-transform: uppercase;
              margin-left: calc(8px * var(--device-pixel-ratio));
              border-bottom: calc(1px * var(--device-pixel-ratio)) solid #fff;
          }
      }
  }
}

.tab {
  .cards {
      position: unset;
      margin-bottom: calc(4px * var(--device-pixel-ratio));
      order: 0;
      width: 240px;
      height: 116px;
      display: flex;
      flex-direction: initial;
      padding: 13px 28px;
      gap: 28px;
      .line {
          height: 1px;
          width: 100%;
          background-color: rgba(135, 135, 148, 0.5);
          transform: rotate(90deg);
          margin-top: 51px;
      }
      &-body {
          height: calc(50px * var(--device-pixel-ratio));
          margin-bottom: calc(24px * var(--device-pixel-ratio));
      }
      &-element {
          width: 64px;
          height: 82px;
          .title {
              margin-bottom: 0px;
              font-size: 12px;
              text-align: center;
          }
      }
      &-list {
          width: calc(64px * var(--device-pixel-ratio));
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          gap: 8px;
          &.history-card-list-view {
              width: calc(200px * var(--device-pixel-ratio));
          }
          // &.revers {
          //   .cards-list-point {
          //     margin-right: calc(16px * var(--device-pixel-ratio));
          //     margin-left: calc(0px * var(--device-pixel-ratio));
          //   }
          //   & .card {
          //     &:last-child {
          //       margin-right: calc(17px * var(--device-pixel-ratio));
          //       margin-left: 0;
          //     }
          //     &:not(:last-child):not(:first-child) {
          //       margin-right: calc(8px * var(--device-pixel-ratio));
          //       margin-left: 0;
          //     }
          //   }
          // }
          &-point {
              order: 4;
              position: unset;
              font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.56px * var(--device-pixel-ratio));
              text-transform: uppercase;
              margin-left: calc(16px * var(--device-pixel-ratio));
              margin-bottom: calc(4px * var(--device-pixel-ratio));
          }
          & .card {
              display: flex;
              width: calc(28px * var(--device-pixel-ratio));
              height: calc(46px * var(--device-pixel-ratio));
              margin-left: 0px;
              &:last-child {
                  margin-left: calc(16px * var(--device-pixel-ratio));
                  margin-top: -35px;
              }
              &:not(:last-child):not(:first-child) {
                  margin-left: calc(0px * var(--device-pixel-ratio));
              }
              & .flip-card-back {
                  & .cardIcon {
                      font-size: calc(49px * var(--device-pixel-ratio));
                  }
              }
              &:last-child {
                  transform: rotate(90deg) translate(calc(8px * var(--device-pixel-ratio)), calc(0px * var(--device-pixel-ratio)));
              }
          }
      }
  }
}

@media only screen and (max-width: 540px) {
  .cards {
      position: fixed;
      top: 23.689vh;
      display: flex;
      flex-direction: inherit;
      position: absolute;
      top: -17.6vh;
      transform: translateX(-50%);
      margin: 0;
      order: 0;
      left: 50%;
      width: 100%;
      background: none;
      padding: 0;
      border: none;
      justify-content: space-between;
      height: 60px;
      .line {
          display: none;
      }
      &-element {
          background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7019607843) 0%, rgba(29, 27, 45, 0.9019607843) 100%) 0% 0% no-repeat padding-box;
          height: 9vh;
          width: 33.5vw;
          .title {
              display: none;
          }
      }
      &-element:last-child {
          border-radius: 6px 0px 0px 6px;
          padding-left: 7px;
      }
      &-element:first-child {
          border-radius: 0px 6px 6px 0px;
          padding-left: 9px;
      }
      &.threeD {
          top: -3vh;
      }
      &-body {
          height: 45px;
      }
      &-list {
          width: 100px;
          position: relative;
          // padding-top: 1vh;
          &.history-card-list-view {
              width: 120px;
          }
          &-point {
              right: 2.5vw;
              top: -0.5vh;
              width: fit-content;
              font: 10px/12px "SF Pro Text Medium";
              letter-spacing: 0.4px;
              margin-right: 0;
              .text {
                  display: none;
              }
          }
          & .card {
              display: flex;
              width: 7.7vw;
              height: 45px;
              &:last-child {
                  margin-left: calc(7px * var(--device-pixel-ratio));
              }
              &:not(:last-child):not(:first-child) {
                  margin-left: 4px;
              }
              & .flip-card-back {
                  & .cardIcon {
                      font-size: 39px;
                      margin-bottom: 2px;
                  }
              }
              &:last-child {
                  transform: rotate(90deg) translate(calc(4px * var(--device-pixel-ratio)), calc(0px * var(--device-pixel-ratio)));
              }
          }
      }
  }
}