.bet-board {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: calc(8px * var(--device-pixel-ratio));
  width: calc(956px * var(--device-pixel-ratio));
}
.middlePc{
  .bet-board {
    width: calc(956px * var(--device-pixel-ratio));
}
  }


.smallPc {
  .bet-board {
    margin-bottom: calc(14px * var(--device-pixel-ratio));
  }
}

.tab {
  .bet-board {
    width: 100%;
    position: absolute;
    margin: unset;
    bottom: calc(390px * var(--device-pixel-ratio));
    order: 0;
    &.three-d-size {
      margin-bottom: calc(25px * var(--device-pixel-ratio));
    }
  }
}

@media only screen and (max-width: 540px) {
  .bet-board {
    width: 91.3vw;
    margin: 0 auto;
    height: 100%;
    bottom: 3vh;
  }
}