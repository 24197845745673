.modal {
  overflow: hidden;
  position: fixed;
  right: calc(20px * var(--device-pixel-ratio)) ;
  top: calc(107px * var(--device-pixel-ratio));
  background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
  border:calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
  border-radius: calc(8px * var(--device-pixel-ratio));
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity ,  ease-in-out 200ms;
  &-header {
    padding: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(32px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &-title {
      text-align: left;
      font: 900  calc(14px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(0.56px * var(--device-pixel-ratio));
      color: rgba(255, 255, 255, 1);
    }
    & .modal-header-control {
      width: fit-content;
      line-height: 0px;
      & button:not(:last-child) {
        margin-right: calc(8px * var(--device-pixel-ratio));
      }
    }
  }
  &-body {
    padding: 0 calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
    height: calc(860px * var(--device-pixel-ratio));
    overflow-y: auto;
  }
  &.history{
    width: calc(561px * var(--device-pixel-ratio));
    height: calc(100% - 127px * var(--device-pixel-ratio));
  }
  &.right-menu {
    padding: 0 ;
  }
  &.rules {
    width: calc(650px * var(--device-pixel-ratio));
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  & .title {
    padding: 0 0 calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
    text-align: left;
    font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    color: #FFFFFF;
  }
}

.smallPc {
  .modal {
    top: calc(91px * var(--device-pixel-ratio));

    &-header {
      padding: calc(19px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(18px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
    }

    &.rules {
      width: calc(770px * var(--device-pixel-ratio));
      height: calc(690px * var(--device-pixel-ratio));

      & .modal-body {
        height: calc(620px * var(--device-pixel-ratio));
        margin-right: calc(20px * var(--device-pixel-ratio));
      }
    }

    &.history {
      width: calc(553px * var(--device-pixel-ratio));
      height: calc(100% - 107px * var(--device-pixel-ratio));
    }
  }
}

.tab {
  .modal {
    top: calc(115px * var(--device-pixel-ratio));

    &.rules {
      width: calc(736px * var(--device-pixel-ratio)) !important;
      height: calc(882px * var(--device-pixel-ratio));

      & .modal-body {
        margin-right: calc(20px * var(--device-pixel-ratio));
        height: calc(785px * var(--device-pixel-ratio));
      }
    }

    &.history {
      width: calc(553px * var(--device-pixel-ratio));
    }
  }
}

@media only screen and (max-width: 540px) {
  .modal {
    top: 11.19vh;
    border-radius: 8px;
    border-width: 1px;
    right: 0;

    &.history {
      width: 97.86vw !important;
      height: calc(100% - 13.19vh);
      max-height: 85vh;
      left: 0;
      margin: auto;
    }

    &.right-menu {
      width: 230px;
      right: 12px;
    }

    &.rules {
      width: 96vw !important;
      height: 86.956vh;
      right: 0;
      left: 0;
      margin: auto;

      & .modal-body {
        height: 70vh;
        margin-right: 20px;
        overflow-x: hidden;
      }
    }

    & .modal-header {
      padding: 20px 20px 20px 20px;

      &-control {
        & .icon {
          width: 24px;
        }
      }
    }

    & .title {
      padding: 16px 0 20px 16px;
      font: 14px/14px "SF Pro Text Medium";
      letter-spacing: 0.56px;
    }

    & .menu-list {
      padding-bottom: 20px;

      & .menu-list-item {
        text-shadow: 1px 1px 2px black;
        font-size: 14px;
        height: 36px;
        letter-spacing: 0.56px;
        padding-left: 12px;

        & svg {
          width: 31px;
          height: 31px;
          margin-right: 7px;
        }

        &:not(:first-child) {
          margin-top: 14px;
        }
      }
    }
  }
}